import * as bootstrap from '../node_modules/bootstrap/dist/js/bootstrap.min.js'

// // Close mobile nav when clicking outside of it.
// document.addEventListener('click', function (event) {
//   console.log("click")
//   var clickover = event.target;
//   var _opened = document.querySelector('.navbar-collapse').classList.contains('show');
//   if (_opened === true && !clickover.classList.contains('navbar-toggle')) {
//     document.querySelector('button.navbar-toggler').click();
//   }
// });

// Toast
const toast = new bootstrap.Toast(document.getElementById('toast'));
const toastBody = document.getElementById('toast-body');

// Gloabl vars, meh..
let socket;
let players;

// Websocket setup
const socketConnect = () => {
  const proto = (location.protocol == 'https:') ? 'wss' : 'ws';
  const socket_reconnect_interval = 1000;
  socket = new WebSocket(`${proto}://${location.host}/ws`);

  // Join room on websocket open
  socket.addEventListener('open', () => {
    console.log('websocket open');
    toast.hide()
    socket.send(JSON.stringify({
      action: 'join',
      room: location.pathname.split('/')[2],
    }));
  });

  // Handle socket error
  socket.addEventListener('error', (e) => {
    console.log('websocket error', e);
  });

  // Handle socket close
  socket.addEventListener('close', () => {
    if (!toast.isShown()) {
      toastBody.textContent = 'Websocket disconnected';
      toast._config.autohide = false;
      toast.show();
    }
    console.log('websocket close');
    setTimeout(socketConnect, socket_reconnect_interval);
  });

  // Handle incoming websocket messages
  socket.addEventListener('message', (e) => {
    let msg = JSON.parse(e.data);
    console.log('got message', msg);
    switch (msg.action) {
       case 'error':
        toastBody.textContent = msg.error;
        toast._config.autohide = true;
        toast.show();
        break;

      case 'state':
        document.getElementById('motd').classList.add('d-none');
        document.getElementById('scoreboard').classList.remove('d-none');
        players = msg.state.players;
        if (Object.keys(players).length > 0) {
          render();
        } else {
          document.getElementById('motd').classList.remove('d-none');
          document.getElementById('scoreboard').classList.add('d-none');
        }
        break;

      case 'score':
      case 'sub':
        setMark(document.getElementById(`${msg.player}-${msg.point}`), msg.value);
        setTotal(msg.player, msg.total);
      break;
    }
  });
}


// Handle new player additions
document.getElementById('add-player-form').addEventListener('submit', (e) => {
  e.preventDefault();
  let input = document.getElementById('add-player-input');
  socket.send(JSON.stringify({
    action: 'add-player',
    player: input.value,
  }));
  input.value = '';
});


// Reset game
document.getElementById('reset-players').addEventListener('click', () => {
  socket.send(JSON.stringify({
    action: 'reset-players',
  }));
});


// Reset score
document.getElementById('reset-score').addEventListener('click', () => {
  socket.send(JSON.stringify({
    action: 'reset-score',
  }));
});


// Get list of the player ids sorted by position
const playersSorted = () => {
  return Object.keys(players).sort((x, y) => {
    if (players[x].position < players[y].position) {
      return -1;
    }
    if (players[x].position > players[y].position) {
      return 1;
    }
    return 0
  });
}

// Render the scoreboard
const render = () => {
  // Get cols
  let leftNames = document.getElementById('left-names');
  let leftTicks = document.getElementById('left-ticks');
  let leftScore = document.getElementById('left-score');
  let rightNames = document.getElementById('right-names');
  let rightTicks = document.getElementById('right-ticks');
  let rightScore = document.getElementById('right-score');

  // Reset col state, lets start fresh
  leftNames.innerHTML = '';
  leftTicks.innerHTML = '';
  leftScore.innerHTML = '';
  rightNames.innerHTML = '';
  rightTicks.innerHTML = '';
  rightScore.innerHTML = '';

  let keys = playersSorted();
  let maxPerSide = Math.ceil(keys.length / 2);

  keys.forEach((id, i) => {
    // Build name col
    let name = document.createElement('div');
    name.innerHTML = players[id].name;
    name.classList.add('player-name', 'column', `c-${maxPerSide}`);

    // Build ticks col
    let ticks = document.createElement('div');
    ticks.classList.add('column', `c-${maxPerSide}`);
    ['20', '19', '18', '17', '16', '15', 'Bull'].forEach(point => {
      let tick = document.createElement('div');
      tick.setAttribute('id', `${id}-${point}`);
      tick.classList.add('score-toggle');
      ticks.appendChild(tick);
      setMark(tick, players[id].score[point]);

      // Add event listeners click and right click (contextmenu)
      // for changing score
      tick.addEventListener('click', (e) => {
        socket.send(JSON.stringify({
          action: 'score',
          player: id,
          point: point
        }));
      });

      tick.addEventListener('contextmenu', (e) => {
        e.preventDefault();
        socket.send(JSON.stringify({
          action: 'sub',
          player: id,
          point: point
        }));
      });
    });

    // Build score col
    let score = document.createElement('div');
    score.classList.add('score', 'column', `c-${maxPerSide}`);
    score.setAttribute('id', `${id}-score`);

    // Insert columns into the DOM in the correct order based on side
    if ( i < maxPerSide ) {
      leftNames.insertBefore(name, leftNames.firstChild);
      leftTicks.insertBefore(ticks, leftTicks.firstChild)
      leftScore.insertBefore(score, leftScore.firstChild);
    } else {
      rightNames.appendChild(name);
      rightTicks.appendChild(ticks);
      rightScore.appendChild(score);
    }

    // Set the total score
    setTotal(id, players[id].total);
  });
}

// Set the score marker for marker to the provided val
const setMark = (target, val) => {
  target.innerHTML = '';
  if (val === 0) {
    // Display just the add tick mark
    let mark = document.createElement('span');
    mark.innerHTML = 'add';
    mark.classList.add('tick', 'material-symbols-outlined', 'add');
    target.appendChild(mark);
  } else {

    // Display tick marks
    for (let i = 1; i <= val && i <= 3; i++) {
      let mark = document.createElement('span');
      let innerHTML;
      if (i === 1 || i === 2) {
        innerHTML = 'pen_size_2';
      } else if (i === 3) {
        innerHTML = 'brightness_1'
      }
      mark.innerHTML = innerHTML;
      mark.classList.add('tick', 'material-symbols-outlined', `tick-${i}`);
      target.appendChild(mark);
    }

    // Display points if exists
    if (val > 3) {
      let point = document.createElement('span');
      point.classList.add('point')
      point.innerHTML = `${val-3}`;
      target.appendChild(point);
    }
  }
}


// Display total sum of points
const setTotal = (player, val) => {
  let total = document.getElementById(`${player}-score`);
  if (val > 0) {
    total.innerHTML = val;
  } else {
    total.innerHTML = '';
  }
}


// Open the socket and lets go!
socketConnect();